












































































































import { Component, Ref, Mixins, Watch } from "vue-property-decorator";
import { Item, ItemFilter } from "@/graphql/client";
import itemService from "@/service/itemService";
import { NotificationState } from "@/store/modules/notification";
import ItemEditDialog from "@/components/organisms/item/ItemEditDialog.vue";
import ItemEditBundlabeDialog from "@/components/organisms/item/ItemEditBundlabeDialog.vue";
import ItemDeleteDialog from "@/components/organisms/item/ItemDeleteDialog.vue";
import ItemImportDialog from "@/components/organisms/item/ItemImportDialog.vue";
import ItemExportDialog from "@/components/organisms/item/ItemExportDialog.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinSearchTimer from "@/components/mixins/MixinSearchTimer.vue";
import moment from "moment";

@Component({
  components: {
    ItemEditDialog,
    ItemDeleteDialog,
    ItemExportDialog,
    ItemImportDialog,
    ItemEditBundlabeDialog,
  },
})
export default class ItemList extends Mixins(
  MixinFormatter, 
  MixinSearchTimer
) {
  @Ref() readonly editDialog!: ItemEditDialog;
  @Ref() readonly editBundleableDialog!: ItemEditBundlabeDialog;
  @Ref() readonly deleteDialog!: ItemDeleteDialog;
  @Ref() readonly exportDialog!: ItemExportDialog;
  @Ref() readonly importDialog!: ItemImportDialog;
  //---------------------------
  // data
  //---------------------------
  items: Item[] = [];
  headers = [
    {
      text: "大進品番",
      value: "daishinCode",
      width: "150px",
    },
    {
      text: "WEB品番",
      value: "webCode",
      width: "150px",
    },
    {
      text: "メーカー品番",
      value: "makerCode",
      width: "150px",
    },
    {
      text: "名称",
      value: "name",
    },
    {
      text: "定価（税抜）",
      value: "price",
      width: "150px",
    },
    {
      text: "公開開始日",
      value: "publicationDate",
      width: "10%",
      formatter: this.formatDateTime,
    },
    {
      text: "公開終了",
      value: "publicationEndDate",
      width: "10%",
      formatter: this.formatDateTime,
    },
    {
      text: "",
      value: "editBundleable",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];

  loadingDataGrid = false;
  searchName = "";
  searchMakerCode = "";
  searchCatalogCode = "";
  searchKeyword = "";


  //pagenation
  totalItems = 0;
  itemDataOptions:{
    page: number;
    itemsPerPage: number;
    sortBy: string[];
    sortDesc: boolean[];
    groupBy: string[];
    groupDesc: boolean[];
  } = {
    page: 1,
    itemsPerPage: 20,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
  };
  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  @Watch('itemDataOptions')
  public onChangeItemDataOptions() {
    this.searchTimeOut(this.searchItems);
  }
  
  @Watch('searchName')
  public onChangeSearchName() {
    this.searchTimeOut(this.searchItems);
  }
  
  @Watch('searchKeyword')
  public onChangeSearchKeyword() {
    this.searchTimeOut(this.searchItems);
  }
  
  @Watch('searchMakerCode')
  public onChangeSearchMakerCode() {
    this.searchTimeOut(this.searchItems);
  }

  @Watch('searchCatalogCode')
  public onChangeSearchCatalogCode() {
    this.searchTimeOut(this.searchItems);
  }
  /**
   * 商品を検索
   */
  public searchItems(): void
  {
    const { sortBy, sortDesc, page, itemsPerPage } = this.itemDataOptions;

    let filter: ItemFilter = {};

    if (this.searchKeyword) {
      filter.keyword = this.searchKeyword;
    }
    if (this.searchName) {
      filter.name = this.searchName;
    }
    if (this.searchMakerCode) {
      filter.makerCode = this.searchMakerCode;
    }
    if (this.searchCatalogCode) {
      filter.catalogCode = this.searchCatalogCode;
    }

    let skip = ( page - 1 ) * itemsPerPage
    let take = itemsPerPage

    this.loadingDataGrid = true;
    //トータル取得
    itemService.countItems(filter).then((totalNum) => {
      this.totalItems = totalNum;
      //検索結果取得
      itemService.searchItems(filter, skip, take).then((items) => {
        this.items = items;
        this.loadingDataGrid = false;
      });
    });
  }

  async fetchData(): Promise<void> {
    this.searchItems();
  }

  /**
   * 日付を「YYYY/MM/DD HH:00」形式の文字列にして返します.
   */
  public formatDateTime(value: string): string {
    return value ? moment(value).format("YYYY-MM-DD HH:00") : "";
  }
  /**
   * 商品編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(itemService.defaultItem);
  }

  /**
   * 商品編集ダイアログを表示します.
   */
  public openEditDialog(item: Item): void {
    this.editDialog.open(item);
  }

  /**
   * 商品編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: Item): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("商品を更新しました！");
  }

  /**
   * 商品編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: Item): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("商品を登録しました！");
  }

  /**
   * 商品インポートダイアログを表示します.
   */
  public openImportDialog(item: Item): void {
    this.importDialog.open(item);
  }
  /**
   * 商品インポートダイアログの処理成功時
   */
  public onImportSuccess(items: Item[]): void {
    this.importDialog.close();
    NotificationState.notifySuccess("商品をインポートしました！");
  }

  public onImportError(error: Error): void {
    this.importDialog.close();
    NotificationState.notifyError(error.message);
  }

  /**
   * 商品エクスポートダイアログを表示します.
   */
  public openExportDialog(item: Item): void {
    this.exportDialog.open(item);
  }

  /**
   * 商品エクスポートダイアログの処理成功時
   */
  public onExportSuccess(url: string): void {
    this.exportDialog.close();
    NotificationState.notifySuccess("商品をエクスポートしました！");
  }

  public onExportError(error: Error): void {
    this.exportDialog.close();
    NotificationState.notifyError(error.message);
  }

  /**
   * 商品削除ダイアログを表示します.
   */
  public openDeleteDialog(item: Item): void {
    this.deleteDialog.open(item);
  }

  /**
   * 商品削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: Item): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("商品を削除しました！");
  }

  /**
   * バンドル商品編集ダイアログを表示します.
   */
  public openEditBundleableDialog(item: Item): void {
    this.editBundleableDialog.open(item);
  }

  /**
   * バンドル商品編集ダイアログの更新成功時.
   */
  public onUpdateBundleableSuccess(updated: Item): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editBundleableDialog.close();
    NotificationState.notifySuccess("バンドル商品を更新しました！");
  }
}
